
import Axios from "../Config/help"
import {
    IssueUpData ,
    AllHelpUpData,
    AddHelpCommentType
} from "./interfaceType"

interface HeadType<T=string>{
    userId:T|number;
    token:T;
}
interface DefaultBodyType<T=string,Y=number>{
    pageNo:T|Y;
    pageSize:T|Y;
}

// 发布互助
export function IssueHelp(headers:HeadType,body:IssueUpData):Promise<any>{
    return (
        Axios({
            url:"/helpAll/create",
            method:"POST",
            headers,
            data:body
        })
    )
}

//支付前 请求数据
export function HelpBefore (headers:HeadType,body:{ orderId:string; }):Promise<any>{
    return (
        Axios({
            url:"/weixin/weixinApiPay",
            method:"POST",
            headers,
            data:body
        })
    )
}

//支付后 请求数据
export function HelpAfter (headers:HeadType,body:{ orderId:string; }):Promise<any>{
    return (
        Axios({
            url:"/app/weixin/callBack4helpAll",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 请求互助类型
export function HelpTypeList(headers:HeadType,body:{ school:string }):Promise<any>{
    return (
        Axios({
            url:"/helpAllType/getList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 互助广场
export function AllHelp(headers:HeadType,body:AllHelpUpData):Promise<any>{
    return (
        Axios({
            url:"/helpAll/getList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 我的互助列表
export function MyHelp(headers:HeadType,body:AllHelpUpData):Promise<any>{
    return (
        Axios({
            url:"/helpAll/getMyPushList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除互助
export function RemoveHelp(headers:HeadType,body:{ id:string|number }):Promise<any>{
    return (
        Axios({
            url:"/helpAll/remove",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 请求详情
export function HelpSuc(headers:HeadType,body:{ id:string|number }):Promise<any>{
    return (
        Axios({
            url:"/helpAll/query4id",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 取消订单
export function BackHelpOrder(headers:HeadType,body:{ id:string|number }):Promise<any>{
    return (
        Axios({
            url:"/helpAll/cancel",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 退款
export function BackPay(headers:HeadType,body:{ orderId:string }):Promise<any>{
    return (
        Axios({
            url:"/app/weixin/refund4helpAll",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 添加评论
export function AddComment(headers:HeadType,body:AddHelpCommentType):Promise<any>{
    return (
        Axios({
            url:"/helpAllMessage/create",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 删除评论
export function RemoveComment(headers:HeadType,body:any):Promise<any>{
    return (
        Axios({
            url:"",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 我的接单
export function TakeHelp(headers:HeadType,body:any):Promise<any>{
    return (
        Axios({
            url:"/helpAll/getMySendList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 发布人 确定接单
export function HelpAffirmOrder(headers:HeadType,body:{ id:string|number; }):Promise<any>{
    return (
        Axios({
            url:"/helpAll/submit",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 接单人确认完成
export function HelpAffirm(headers:HeadType,body:{ id:string|number; }):Promise<any>{
    return (
        Axios({
            url:"/helpAll/sendFinish",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 抢单
export function HelpRace(headers:HeadType,body:{ id:string|number; }):Promise<any>{
    return (
        Axios({
            url:"/helpAll/secondKill",
            method:"POST",
            headers,
            data:body
        })
    )
}
