import store from "../../index"
import { VuexModule,getModule,Module,Mutation } from "vuex-module-decorators"
import { 
    HelpTypeT,
    MyHelpItem
} from "@/Type/index"
import { 
    AllHelpUpData
 } from "@/Api/Help/interfaceType"

interface IssueUpDataType<S=string,T=number>{
    NavIndex:T;
    content:S;
    ImgList:Array<string>;
    Money:T;
    IsAddress:boolean;
}

interface HelpScrollNum <T=number>{
    AllHelpScroll:T;
    MyHelpScroll:T;
    TakeHelpScroll:T;
}

export interface HelpType<T=string>{
    HelpITL:Array<HelpTypeT>
    HelpIUpData:IssueUpDataType;
    HelpAllUpData:AllHelpUpData;
    HelpAllList:Array<any>
    HelpScroll:HelpScrollNum
    HelpMyList:Array<MyHelpItem>
    HelpMyUpData:AllHelpUpData
    HelpTakeUpData:AllHelpUpData
    HelpTakeList:Array<MyHelpItem>

    SetHelpITL(data:Array<any>):void;
    SetHelpIUpData(data:IssueUpDataType):void;
    SetHelpAllUpData(data:AllHelpUpData):void;
    SetHelpAllList(data:Array<any>):void;
    SetHelpScroll(data:HelpScrollNum):void;
    SetHelpMyList(data:Array<MyHelpItem>):void;
    SetHelpMyUpData( data:AllHelpUpData ):void;
    SetHelpTakeUpData( data:AllHelpUpData ):void;
    SetHelpTakeList( data:Array<MyHelpItem> ):void;
}

@Module({
    store,
    name:"Help",
    dynamic:true
})
export default class Help extends VuexModule implements HelpType{
    HelpIUpData: IssueUpDataType<string, number> = {
        NavIndex:0,
        content:"",
        ImgList:[],
        Money:0,
        IsAddress:true,
    }
    HelpITL:Array<HelpTypeT> = []
    HelpAllUpData:AllHelpUpData = {
        pageNo:1,
        pageSize:15
    }
    HelpAllList:Array<any> = []
    HelpScroll:HelpScrollNum = {
        AllHelpScroll:0,
        MyHelpScroll:0,
        TakeHelpScroll:0
    }
    HelpMyList:Array<MyHelpItem> = []
    HelpMyUpData:AllHelpUpData = {
        pageNo:1,
        pageSize:15
    }
    HelpTakeUpData: AllHelpUpData<string> = {
        pageNo:1,
        pageSize:15
    }
    HelpTakeList: MyHelpItem<string>[] = []

    //类型 缓存
    get GetHelpITL(){
        return this.HelpITL
    }
    @Mutation
    SetHelpITL(data:Array<HelpTypeT>){
        this.HelpITL = data
    }
    // 发布内容 缓存
    get GetHelpIUpData(){
        return this.HelpIUpData
    }
    @Mutation
    SetHelpIUpData(data: IssueUpDataType<string, number>): void {
        this.HelpIUpData = data
    }
    // 请求广场类型
    get GetHelpAllUpData(){
        return this.HelpAllUpData
    }
    @Mutation
    SetHelpAllUpData(data:AllHelpUpData){
        this.HelpAllUpData = data
    }
    //广场列表数据
    get GetHelpAllList(){
        return this.HelpAllList
    }
    @Mutation
    SetHelpAllList(data:Array<any>){
        this.HelpAllList = data
    }
    // 滚动列表
    get GetHelpScroll(){
        return this.HelpScroll
    }
    @Mutation
    SetHelpScroll(data:HelpScrollNum){
        this.HelpScroll = data
    }
    // 我的互助列表
    get GetHelpMyList(){
        return this.HelpMyList
    }
    @Mutation
    SetHelpMyList(data:Array<MyHelpItem>){
        this.HelpMyList = data
    }
    // 我的互助请求数据
    get GetHelpMyUpData(){
        return this.HelpMyUpData
    }
    @Mutation
    SetHelpMyUpData( data:AllHelpUpData ){
        this.HelpMyUpData = data
    }
    // 接单请求类型
    get GetHelpTakeUpData(){
        return this.HelpTakeUpData
    }
    @Mutation
    SetHelpTakeUpData(data: AllHelpUpData<string>): void {
        this.HelpTakeUpData = data
    }
    // 接单列表
    get GetHelpTakeList(){
        return this.HelpTakeList
    }
    @Mutation
    SetHelpTakeList(data: MyHelpItem<string>[]): void {
        this.HelpTakeList = data
    }
}

export const HelpStore = getModule( Help )





